$base-color: #ef684c;
$grey: #667080;
$grey-dark: #405161;
$light-black: #3a3e41;

$checkbox-checked-icon: "data:image/svg+xml;utf8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(%23clip0_219_332)'><rect width='20' height='20' rx='3' fill='%23EF684C'/><path d='M8.50005 12.379L15.3941 5.48425L16.4553 6.54475L8.50005 14.5L3.72705 9.727L4.78755 8.6665L8.50005 12.379Z' fill='white'/></g><defs><clipPath id='clip0_219_332'><rect width='20' height='20' rx='3' fill='white'/></clipPath></defs></svg>";
$radio-checked-icon: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><rect width='20' height='20' rx='3' fill='%23ef684c'/><circle cx='3' cy='3' r='3' transform='translate(7 7)' fill='%23fff'/></svg>";

@mixin placeholder {
    &::placeholder {
        @content;

        opacity: 1;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::-ms-input-placeholder {
        @content;
    }
}

$font-main: "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-secondary: Cairo, "Helvetica Neue", Helvetica, Arial, sans-serif;
