.checkboxes-list {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
}

.label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin: 0;

    &:before {
        content: "";
        width: 20px;
        height: 20px;
        background: #fff;
        border: 2px solid rgba(102, 112, 128, .3);
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 2px;
        box-sizing: border-box;
    }
}

.custom-checkbox {
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute;
    margin: -1px;
    height: 1px;
    width: 1px;
    border: 0;
    padding: 0;
    overflow: hidden;
    word-wrap: normal;

    &:checked + label:before {
        content: "";
        background: url("data:image/svg+xml;utf8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><g clip-path='url(%23clip0_219_332)'><rect width='20' height='20' rx='3' fill='%23EF684C'/><path d='M8.50005 12.379L15.3941 5.48425L16.4553 6.54475L8.50005 14.5L3.72705 9.727L4.78755 8.6665L8.50005 12.379Z' fill='white'/></g><defs><clipPath id='clip0_219_332'><rect width='20' height='20' rx='3' fill='white'/></clipPath></defs></svg>") no-repeat;
        border: none;
    }
}
