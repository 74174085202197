.bannerWrapper svg {
    display: block;
    width: 100%;
}

.modal-title,
.modal-text {
    color: #667080;
}

.modal-title {
    font: 700 34px/1.3 "Cairo", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 25px 0 20px;

    @media screen and (max-width: 575px) {
        font-size: 28px;
    }
}

.modal-text {
    font: 400 16px/1.8 "Cairo", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
