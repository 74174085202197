.label {
    & span {
        margin-left: 0;
    }
}

.required {
    & span::after {
        color: #ff0000;
        content: '*';
        margin-left: 5px;
    }
}
