.notification-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  text-decoration: none !important;
  border: 1px solid #f47e5e;
  color: #f47e5e;
  font-size: 16px !important;
  font-weight: 700 !important;
  white-space: nowrap;
  margin-left: 20px;
}

.icon-user {
  max-width: 24px;
  height: 24px;
  margin-right: 18px;
}
