@import "./../core/variables";

.support-container {
    background: #fff;
    width: 100%;
    max-width: 600px;
    padding: 45px 40px 40px;
    margin: 0 auto;
    box-sizing: border-box;

    @media screen and (max-width: 567px) {
        padding: 20px;
    }

    h1 {
        text-align: left;
        font-size: 24px;
        margin-bottom: 20wpx;
    }

    p {
        line-height: 30px;
    }

    .support-form {
        overflow: hidden;
    }

    .support-form-row {
        margin-bottom: 20px;
    }

    .support-form-label {
        display: block;
        font-size: 14px;
        font-style: normal;
        color: #3a3e41;

        &.error {
            color: #b61c1c;
        }
    }

    .support-form-input,
    .support-form-textarea {
        display: block;
        width: 100%;
        box-shadow: none !important;
        color: #3a3e41;
        font-style: normal !important;
        border-radius: 0 !important;
        border-color: #667080;

        @include placeholder {
            color: #ccc;
        }

        &.error {
            border-color: #b61c1c;
        }
    }

    .support-form-input {
        padding: 10px 15px !important;
        height: auto !important;
    }

    .support-form-textarea {
        overflow: auto;
        height: 100px;
    }

    .support-form-error {
        display: block;
        color: #b61c1c;
        font-size: 12px;
        margin-top: 4px;
    }

    .support-btn {
        background: $base-color;
        border-radius: 0;
        border: none;
        letter-spacing: normal;
        font-size: 16px;
        font-weight: 700;
        float: right;
        padding: 11px 15px;
        min-width: 180px;
        text-shadow: none;
        transition: all .2s ease;

        &:hover,
        &:active,
        &:focus {
            opacity: .8;
            text-shadow: none;
            border: none;
            box-shadow: none;
            background-image: none;
        }
    }
}
