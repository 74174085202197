@import "./../core/variables";

.questionnaire-page-title {
    font-size: 24px;
    margin-bottom: 12px;
    font-family: $font-secondary;
}

.questionnaire-page-description {
    font-size: 16px;
    color: $grey;
}

nav.pgn__tabs {
    margin-bottom: 35px;
    align-items: center;

    .nav-item {
        padding: 10px 40px;
        color: $grey;
        text-decoration: none !important;

        &:hover {
            background: none !important;
        }

        &.active {
            color: $base-color;
            border-bottom-color: $base-color;
        }
    }

    .pgn__tab_more button {
        letter-spacing: normal;
        border-bottom: none !important;
    }

    .dropdown-item {
        box-sizing: border-box;
        text-decoration: none !important;

        &.active,
        &:active,
        &:focus {
            background: $base-color !important;
            color: #fff;
        }
    }
}

.tab-content {
    color: $light-black;
}

#tabs-tabpane-work_info,
#tabs-tabpane-students_info {
    > .form-row {
        padding: 23px 0 25px;

        &:first-child {
            padding-top: 0;
        }

        .pgn__form-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            > label {
                font-weight: 500;
                margin-bottom: 15px;
                width: 100%;
            }
        }

        .pgn__form-label {
            font-size: 16px;
            display: block;
            font-style: normal;
            color: $grey-dark;
        }

        .pgn__form-control-decorator-group {
            width: 100%;

            @media screen and (min-width: 992px) {
                flex: 0 0 440px;
            }
        }

        .pgn__form-text {
            width: 100%;
            font-size: 12px;
            line-height: 22px;
            color: $light-black;
            box-sizing: border-box;
            padding: 5px 0 0;

            @media screen and (min-width: 992px) {
                flex: 0 0 calc(100% - 440px);
                padding: 0 0 0 35px;
            }
        }

        .form-control {
            width: 100%;
            border-radius: 0;
            border: 1px solid $grey;
            font-style: normal;
            box-sizing: border-box;
            font-size: 16px;
            color: $light-black;
        }

        input.form-control {
            padding: 10px 12px;
            height: auto;
        }

        input.is-invalid {
            border-color: $base-color;
        }

        select.form-control {
            height: 47px;
        }

        .invalid-feedback {
            color: $base-color;
        }

        .pgn__form-radio,
        .pgn__form-checkbox {
            display: flex;
            align-items: center;
        }

        .pgn__form-radio + .pgn__form-radio,
        .pgn__form-checkbox + .pgn__form-checkbox {
            margin-top: 15px;
        }

        .pgn__form-checkbox-input,
        .pgn__form-radio-input {
            clip: rect(1px 1px 1px 1px);
            clip: rect(1px, 1px, 1px, 1px);
            position: absolute;
            margin: -1px;
            height: 1px;
            width: 1px;
            border: 0;
            padding: 0;
            overflow: hidden;
            word-wrap: normal;

            &:checked + div label:before {
                content: "";
                border: none;
            }

            + div label {
                padding-left: 30px;
                margin: 0;
                position: relative;
                color: $grey-dark;
                font-size: 16px;

                &:before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    border: 2px solid rgba(102, 112, 128, .3);
                    position: absolute;
                    left: 0;
                    top: 3px;
                    box-sizing: border-box;
                }
            }
        }

        .pgn__form-checkbox-input {
            &:checked + div label:before {
                background: url($checkbox-checked-icon) no-repeat;
            }

            + div label:before {
                border-radius: 3px;
            }
        }

        .pgn__form-radio-input {
            &:checked + div label:before {
                background: url($radio-checked-icon) no-repeat;
            }

            + div label:before {
                border-radius: 50px;
            }
        }
    }
}

//questionnaire select search

body .select-search {
    flex: 0 0 440px;
    width: 100%;

    @media screen and (max-width: 991px) {
        flex: 0 0 100%;
    }

    &__value:after {
        border-right-width: 1.5px !important;
        border-bottom-width: 1.5px !important;
        top: calc(50% - 7px);
        right: 10px;
        width: 9px;
        height: 9px;
    }

    &__input {
        height: 47px;
        border: 1px solid #767676;
        border-radius: 0;
        box-shadow: none;
        font-size: 16px;
        color: #3a3e41;
        font-family: $font-main;

        @include placeholder {
            color: #3a3e41;
        }

        &:hover {
            border-color: #767676 !important;
        }
    }

    &__select {
        border-left: 1px solid #767676;
        border-right: 1px solid #767676;
        border-bottom: 1px solid #767676;
        border-radius: 0 !important;
        top: 47px !important;
    }

    &__options {
        list-style: none;
        margin: 0;
        padding: 0;
        border-radius: 0 !important;
    }

    &__option {
        text-align: left;
        background: none;
        box-shadow: none !important;
        text-shadow: none !important;
        text-transform: none;
        letter-spacing: normal;
        border: none;
        border-radius: 0;
        font-size: 16px;
        display: block;
        width: 100%;
        padding: 4px 10px;
        color: #3a3e41 !important;
        height: auto;
        font-weight: normal;

        &:hover {
            background: rgba($grey, .1) !important;
            border: none;
        }

        &.is-selected {
            background: rgba($base-color, 1) !important;
            color: #fff !important;
        }
    }

    &__not-found {
        text-align: left;
        font-size: 16px;
        color: #3a3e41;
    }
}




