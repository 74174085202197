.message {
    border-radius: 1px;
    padding: 10px 15px;
    font-weight: 600;
    margin: 10px 0 12px;
}

.success-message {
    @extend .message;

    background: #f2f9f2;
    border-top: 2px solid #008401;
    color: #008401;
    display: block;
}

.error-message {
    @extend .message;

    background: #f7e9e8;
    border-top: 2px solid #b72019;
    color: #b72019;
    display: block;
}

.report p {
    line-height: 2;
}

.report-input {
    max-width: 800px;
    width: 70%;
    margin-bottom: 0 !important;
    background: #fff;
    border: 1px solid #c8c8c8;
    border-radius: 0;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, .6), inset 0 0 3px 0 rgba(0, 0, 0, .1);
    box-sizing: border-box;
    font: 400 1em "Roboto Slab", "Helvetica Neue", Helvetica, Arial, sans-serif;
    height: 35px;
    padding: 5px 12px;
    vertical-align: top;
}
