.container {
    display: flex;
    justify-content: flex-end;
    padding-top: 25px;

    button {
        padding: 14px 20px;
        font-size: 16px;
        font-weight: 700;
        transition: all .2s ease;
        border-radius: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: none;
        text-shadow: none;
        box-shadow: none;

        @media screen and (max-width: 575px) {
            width: 48%;
            margin: 0 !important;
            font-size: 15px;
            padding: 14px 0 !important;
        }

        &:hover,
        &:active,
        &:focus {
            opacity: .8;
            text-shadow: none;
            border: none;
            box-shadow: none;
            background-image: none;
        }
    }

    @media screen and (max-width: 575px) {
        justify-content: space-between;
    }
}

.secondary {
    background: #ccd6de !important;
    color: #405161 !important;

    svg {
        margin-right: 15px;
    }
}

.primary {
    background: #f47e5e;
    color: #fff;
    margin-left: 15px;

    svg {
        margin-left: 15px;
    }
}
